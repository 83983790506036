.newsList {
  .item {
    margin-bottom: var(--spacing-16);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .newsList {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    .item {
      width: calc(50% - 0.6rem);
      margin: 0;
      height: 260px;
    }
  }
}
