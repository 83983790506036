@import "../../../src/styles/sass/config/mixin";

.cardWrap {
    margin-bottom: var(--spacing-12);
    &:last-of-type {
        margin-bottom: var(--spacing-32);
    }
}
.card {
    background-color: var(--clr-white);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
    .deviceWrap {
        padding: var(--spacing-16) var(--spacing-12);
        display: flex;
        align-items: baseline;
        column-gap: var(--spacing-12);
    }
    .deviceDetails {
        display: flex;
        flex-direction: column;
        row-gap: var(--spacing-4);
        width: 100%;
    }
    .infoWrap {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-8);
        height: var(--spacing-24);
    }
    .deviceInfo {
        display: flex;
        min-width: 9rem;
        column-gap: var(--spacing-2);
        padding-right: var(--spacing-8);
        .mobile {
            background-color: var(--clr-primary);
            @include svg-icon("../../../public/assets/icons/mobile-active.svg", 1.6rem, 1.6rem);
        }
        .location {
            background-color: var(--clr-primary);
            @include svg-icon("../../../public/assets/icons/location.svg", 1.6rem, 1.6rem);
        }
    }
    .deviceTitle {
        font-size: var(--font-size-14);
        line-height: 120%;
        color: var(--clr-primary-black);
    }
    .deviceStatus {
        font-size: var(--font-size-12);
        line-height: 120%;
        color: var(--clr-red);
        position: relative;
        padding: var(--spacing-4) var(--spacing-8);
        padding-left: var(--spacing-14);
        &::before {
            content: "";
            position: absolute;
            width: var(--spacing-6);
            height: var(--spacing-6);
            background-color: var(--clr-red);
            border-radius: 50%;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
        }
        &.active {
            color: var(--clr-green-2);
            &::before {
                background-color: var(--clr-green-2);
            }
        }
    }
    .trash {
        display: block;
        @include svg-icon("../../../public/assets/icons/trash.svg", 1.6rem, 1.6rem);
        background-color: var(--clr-primary);
        margin-left: auto;
        margin-right: 0;
    }
}
.drawer {
    padding: var(--spacing-12) var(--spacing-16) 0;
    .card {
        background-color: var(--clr-surface-card);
    }
}
