.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16);
  background-color: var(--clr-main-bg);
  column-gap: var(--spacing-8);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  button,
  a {
    flex-grow: 1;
    min-width: 16rem;
    &:nth-of-type(1) {
      max-width: 16rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16rem;
    }
  }
  @media only screen and (min-width: 768px) {
    max-width: 50rem;
    margin: 0 auto;
    box-shadow: none;
  }
}
