// bs-datepicker

.bs-datepicker {
  .MuiInputLabel-root {
    font-family: var(--font-1);
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-grey-2);
    top: -0.4rem;
    left: 0.8rem;
    z-index: 1;
    transform: translate(0, 20px) scale(1);
    &.Mui-focused,
    &.MuiFormLabel-filled {
      position: relative;
      color: var(--clr-secondary);
      top: 0;
      left: 0;
      transform: translate(0, -1.5px) scale(0.75);
      + .MuiInputBase-root {
        border-color: var(--clr-primary-black);
      }
    }
  }
  .Mui-error {
    .MuiOutlinedInput-root {
      border-color: var(--clr-red);
    }
  }
  .MuiInputBase-root {
    border: 1px solid var(--clr-stroke-disabled);
    border-radius: 0.4rem;
    padding: var(--spacing-12) var(--spacing-8) var(--spacing-8);
    background: var(--clr-white);
    display: flex;
    height: 4.8rem;
    align-items: center;
    margin-top: 0;
    button {
      margin-right: 0;
      padding: 0;
      position: absolute;
      left: 89%;
      right: 0;
      top: 0;
      bottom: 0;
      &:hover {
        background: none;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      svg {
        display: none;
      }
      &:after {
        content: "";
        position: absolute;
        // top: 1.5rem;
        right: 1.6rem;
        @include svg-icon(
          "../../../../public/assets/icons/calendar.svg",
          1.6rem,
          1.6rem
        );
        background-color: var(--clr-primary);
      }
      @media only screen and (min-width: 768px) {
        left: 0;
      }
    }
    &:before,
    &:after {
      content: none;
    }
    &.Mui-focused {
      border-color: var(--clr-primary-black);
    }
    fieldset {
      display: none;
    }
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 1.5rem;
    //   right: 1.6rem;
    //   @include svg-icon("../../../../public/assets/icons/calendar.svg", 1.6rem, 1.6rem);
    //   background-color: var(--clr-primary);
    // }
  }
}

.MuiPickersLayout-toolbar {
  display: none !important;
}

.MuiDateCalendar-root {
  max-height: 36rem !important;
  height: 100% !important;
  @media only screen and (min-width: 768px) {
    width: 35rem !important;
  }
  .MuiPickersCalendarHeader-root {
    position: relative;
    padding-left: var(--spacing-14);
    padding-right: var(--spacing-14);
    margin: var(--spacing-16) 0;
    .MuiPickersCalendarHeader-labelContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .MuiPickersCalendarHeader-label {
        margin: 0;
        font-size: var(--font-size-16);
        font-weight: var(--font-semibold);
        color: var(--clr-secondary);
        font-family: var(--font-1);
      }
      .MuiPickersCalendarHeader-switchViewButton {
        display: none;
      }
    }
    .MuiPickersArrowSwitcher-root {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .MuiIconButton-root {
        margin: 0;
        padding: 0;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  .MuiDateCalendar-viewTransitionContainer {
    .MuiDayCalendar-weekDayLabel {
      width: 4rem;
      height: 4rem;
      margin: 0;
      color: var(--clr-grey-1);
      font-size: var(--font-size-16);
      line-height: 120%;
      font-family: var(--font-1);
    }
    .MuiDayCalendar-slideTransition {
      min-height: initial;
    }
    .MuiDayCalendar-monthContainer {
      position: static;
    }
    .MuiPickersDay-root {
      width: 4rem;
      height: 4rem;
      margin: 0;
      font-size: var(--font-size-16);
      color: var(--clr-secondary);
      font-family: var(--font-1);
      line-height: 100%;
      &.MuiPickersDay-today {
        border-color: var(--clr-stroke-grey);
      }
      &.Mui-selected {
        background-color: var(--clr-primary);
        border-color: var(--clr-primary);
        color: var(--clr-white);
      }
    }
  }
  .MuiPickersYear-yearButton {
    width: 5.3rem;
    height: 2.3rem;
    padding: var(--spacing-6) var(--spacing-8) var(--spacing-4);
    font-family: var(--font-1);
    font-size: var(--font-size-16);
    color: var(--clr-grey-1);
    display: flex;
    align-items: center;
    justify-content: center;
    &.Mui-selected {
      background-color: var(--clr-light-green) !important;
      font-weight: var(--font-semibold);
      color: var(--clr-primary);
      border-radius: 0.4rem;
    }
  }
}
.MuiPickersLayout-actionBar {
  display: flex !important;
  padding: var(--spacing-16) !important;
  justify-content: space-between !important;
  .MuiButton-root {
    padding: var(--spacing-14) var(--spacing-24) var(--spacing-12);
    min-width: 13.6rem;
    border-radius: 0.8rem;
    border: 1px solid transparent;
    font-size: var(--font-size-16);
    font-weight: var(--font-semibold);
    text-align: center;
    display: flex;
    column-gap: var(--spacing-4);
    justify-content: center;
    font-family: var(--font-1);
    line-height: 100%;
    text-transform: none;
    letter-spacing: normal;
    &:first-of-type {
      color: var(--clr-primary);
      background-color: var(--clr-white);
      border-color: var(--clr-primary);
    }
    &:last-of-type {
      color: var(--clr-white);
      background-color: var(--clr-primary);
      border-color: var(--clr-primary);
    }
  }
}
.MuiYearCalendar-root {
  width: 100% !important;
}
