// colors.scss

:root {
  --clr-white: #fff;
  --clr-black: #000;
  --clr-green-1: #4caf50;
  --clr-green-2: #278829;
  --clr-light-green: #e5f2e4;
  --clr-blue: #2a3e59;
  --clr-orange: #ef5617;
  --clr-red: #eb0000;
  --clr-red-light: #da2c38;
  --clr-primary: #193f34;
  --clr-primary-black: #182521;
  --clr-secondary: #4e5553;
  --clr-secondary-2: #183d3d;
  --clr-main-bg: #f3f6ee;
  --clr-surface-green: #d2e4b4;
  --clr-surface-card: #fafbf8;
  --clr-surface-card-2: #e2e9e5;
  --clr-secondary-grey: #d1d1d1;
  --clr-surface-grey: #f5f5f5;
  --clr-surface-light: #f5f9fe;
  --clr-surface-blue: #f1f5f8;
  --clr-grey-1: #767f7c;
  --clr-grey-2: #a5acaa;
  --clr-grey-3: #3b4542;
  --clr-chat-bg: #ebebeb;
  --clr-chat-stroke: #d3dec1;
  --clr-message-bg: #e7eddd;
  --clr-action-disabled: #b4b4b4;
  --clr-action-secondary: #f2f2f2;
  --clr-stroke-disabled: #e5e7e6;
  --clr-stroke-grey: #d0d2d1;
  --clr-chip-leading: #2a3e59;
  --clr-chip-highlight: #af4c4c;
  --clr-chip-latest: #719970;
  --clr-chip-focus: #726086;
  --clr-chip-trending: #f7645f;
  --clr-graph: #34836c;
}
