.title {
  margin-bottom: var(--spacing-16);
}

.subtitle {
  margin-bottom: var(--spacing-32);
  font-size: var(--font-size-14);
  line-height: 120%;
  color: var(--clr-secondary);
}
.cardWrap {
  padding: var(--spacing-24) var(--spacing-16);
  border-radius: 0.8rem;
  border: 1px solid var(--clr-primary);
  .imgWrap {
    width: 18rem;
    height: 18rem;
    margin: 0 auto;
    padding-bottom: var(--spacing-24);
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .dataWrap {
    border-top: 1px solid var(--clr-stroke-grey);
    padding-top: var(--spacing-24);
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--spacing-24);
    .labelValueWrap {
      width: 50%;
      flex-shrink: 0;
      padding-right: var(--spacing-6);
      &:nth-of-type(even) {
        padding-left: var(--spacing-6);
        padding-right: 0;
      }
      .label {
        display: block;
        margin-bottom: var(--spacing-8);
        font-size: var(--font-size-12);
        line-height: 120%;
        color: var(--clr-secondary);
      }
      .value {
        display: block;
        font-size: var(--font-size-14);
        line-height: 120%;
        color: var(--clr-primary-black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--spacing-16);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  border-top: 1px solid var(--clr-stroke-disabled);
  background-color: var(--clr-main-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    flex-grow: 1;
    min-width: 16rem;
    &:nth-of-type(1) {
      max-width: 16rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16rem;
    }
  }
}
