@import "../../../../styles/sass/config/mixin";

.title {
  margin-bottom: var(--spacing-28);
  font-family: var(--font-2);
  font-size: var(--font-size-36);
  line-height: 110%;
  font-weight: var(--font-semibold);
  color: var(--clr-primary-black);
  max-width: 80%;
}

.section {
  margin-bottom: var(--spacing-24);
  &:last-of-type {
    margin-bottom: 0;
  }
  .secTitle {
    font-size: var(--spacing-20);
    line-height: 120%;
    color: var(--clr-secondary);
    margin-bottom: var(--spacing-16);
  }
  .cardTitle {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-8);
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
  }
  .card {
    background-color: var(--clr-white);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
    .stepsList {
      padding: var(--spacing-8) var(--spacing-6);
    }
    .steps {
      counter-increment: stepList 1;
      height: 4.8rem;
      padding: 0 var(--spacing-6);
      display: flex;
      align-items: center;
      column-gap: var(--spacing-12);
      color: var(--clr-primary);
      font-size: var(--font-size-16);
      line-height: 120%;
      position: relative;
      &:before {
        content: counter(stepList);
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border: 1.5px solid #828282;
        color: var(--clr-secondary);
        font-size: var(--font-size-12);
        line-height: 1.8rem;
        border-radius: 50%;
        flex: none;
      }
      &:after {
        content: "";
        background-color: var(--clr-stroke-grey);
        width: 2px;
        position: absolute;
        left: 1.65rem;
        bottom: -1.1rem;
        height: 2.2rem;
      }
      &:last-of-type {
        &:after {
          content: none;
        }
      }
      p {
        padding-top: var(--spacing-2);
      }
    }
    .docList {
      padding: var(--spacing-12);
      .iconTextWrap {
        margin-bottom: var(--spacing-16);
        display: flex;
        align-items: center;
        column-gap: var(--spacing-8);
        &:last-of-type {
          margin-bottom: 0;
        }
        span {
          font-size: var(--font-size-14);
          line-height: 120%;
          color: var(--clr-secondary);
          padding-top: var(--spacing-6);
        }
        button {
          margin-left: auto;
          font-size: var(--font-size-12);
          padding-top: var(--spacing-10);
          padding-bottom: var(--spacing-6);
        }
      }
      i {
        background-color: var(--clr-primary);
        &.identification {
          @include svg-icon(
            "../../../../public/assets/icons/identification.svg",
            2rem,
            2rem
          );
        }
        &.house {
          @include svg-icon(
            "../../../../public/assets/icons/house.svg",
            2rem,
            2rem
          );
        }
        &.trade {
          @include svg-icon(
            "../../../../public/assets/icons/document-text-bl.svg",
            2rem,
            2rem
          );
        }
        &.sign {
          @include svg-icon(
            "../../../../public/assets/icons/edit-2.svg",
            2rem,
            2rem
          );
        }
        &.articles {
          @include svg-icon(
            "../../../../public/assets/icons/document.svg",
            2rem,
            2rem
          );
        }
        &.receipt {
          @include svg-icon(
            "../../../../public/assets/icons/receipt-item.svg",
            2rem,
            2rem
          );
        }
        &.shareholding {
          @include svg-icon(
            "../../../../public/assets/icons/credit-card.svg",
            2rem,
            2rem
          );
        }
      }
    }
  }
}
.drawerWrap {
  padding: var(--spacing-10) var(--spacing-16) 0.7rem;
  .cardDesc {
    font-size: var(--font-size-12);
    line-height: 120%;
    color: var(--clr-secondary);
    margin-bottom: var(--spacing-12);
  }
  .cardCont {
    padding: var(--spacing-12);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
  }
  .list {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-20);
  }
  .item {
    display: flex;
    align-items: baseline;
    column-gap: var(--spacing-8);
  }
  .circle {
    width: var(--spacing-8);
    height: var(--spacing-8);
    border-radius: 50%;
    background-color: var(--clr-primary);
  }
  .text {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-primary-black);
  }
  .content {
    padding: var(--spacing-12);
    border: 1px solid var(--clr-orange);
    border-radius: var(--spacing-4);
    background-color: rgba(239, 86, 23, 0.12);
    margin-top: var(--spacing-8);
    display: flex;
    gap: var(--spacing-4);
    span {
      font-size: var(--font-size-12);
      line-height: 150%;
      color: var(--clr-orange);
    }
    .error {
      display: flex;
      flex: none;
      @include svg-icon(
        "../../../../public/assets/icons/info-circle.svg",
        1.6rem,
        1.6rem
      );
      background-color: var(--clr-orange);
    }
  }
}
