@import "../../styles/sass/config/mixin";

.portfolioHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: var(--spacing-12);
  margin-bottom: var(--spacing-16);
  padding: var(--spacing-16);
  .companyWrap {
    display: flex;
    column-gap: var(--spacing-8);
    max-width: 60%;
  }
  .companyLogo {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
  .companyIntro {
    display: flex;
    gap: var(--spacing-8);
    align-items: flex-start;
    flex-direction: column;
    .companyName {
      font-size: var(--font-size-20);
      line-height: 110%;
      display: block;
      color: var(--clr-primary);
      font-weight: var(--font-semibold);
      font-family: var(--font-2);
    }
    .tag {
      padding: 0.5rem var(--spacing-8) var(--spacing-2);
      border-radius: 0.2rem;
      border: 0.2px solid var(--clr-stroke-grey);
      font-size: var(--font-size-10);
      line-height: 110%;
      color: var(--clr-primary);
      height: 2rem;
      background: linear-gradient(90deg, #e5f2e4 0%, #e5f2e4 0.01%, #eaf3e6 32.72%, #f9f7ed 100%);
      flex-shrink: 0;
    }
  }
  .dataWrap {
    .label {
      display: block;
      text-align: right;
      font-size: var(--font-size-12);
      line-height: 110%;
      color: var(--clr-secondary);
      margin-bottom: var(--spacing-12);
    }
    .value {
      display: block;
      text-align: right;
      font-size: var(--font-size-24);
      line-height: 110%;
      font-weight: var(--font-semibold);
      color: var(--clr-green-1);
    }
  }
}
.reportBody {
  .tabDataList {
    padding: var(--spacing-16);
    background-color: var(--clr-surface-grey);
    margin-bottom: var(--spacing-32);
    .cardItem {
      padding: var(--spacing-12);
      border-radius: 0.4rem;
      background-color: var(--clr-white);
      margin-bottom: var(--spacing-16);
      &:last-of-type {
        margin-bottom: 0;
      }
      .cardHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--spacing-16);
      }
      .cardTitle {
        font-size: var(--font-size-20);
        line-height: 110%;
        font-family: var(--font-2);
        color: var(--clr-primary);
        font-weight: var(--font-bold);
      }
      .subItem {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-12);
        padding: var(--spacing-4) 0;
        margin-bottom: var(--spacing-16);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          border-bottom: 0.5px solid var(--clr-stroke-grey);
          bottom: -0.8rem;
          left: 0;
          right: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
          &::after {
            content: none;
          }
        }
        .growth {
          @include svg-icon("../../../public/assets/icons/growth.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary);
        }
        .industry {
          @include svg-icon("../../../public/assets/icons/industry.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary);
        }
        .climate {
          @include svg-icon("../../../public/assets/icons/climate.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary);
        }
        .text {
          font-size: var(--font-size-14);
          line-height: 120%;
          color: var(--clr-secondary);
          padding-top: var(--spacing-4);
        }
      }
      .cardDesc {
        font-size: var(--font-size-14);
        line-height: 150%;
        color: var(--clr-primary-black);
        + .tableWrap {
          margin-top: var(--spacing-16);
        }
        + .subWrap {
          margin-top: var(--spacing-16);
        }
      }
      .tableWrap {
        .tableBody {
          .tableItem {
            padding: var(--spacing-8) 0;
            border-bottom: 0.5px solid var(--clr-stroke-grey);
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--clr-secondary);
            font-size: var(--font-size-14);
            line-height: 120%;
            margin-bottom: var(--spacing-12);
            .value {
              font-size: var(--font-size-16);
              color: var(--clr-primary-black);
            }
            &:last-of-type {
              border-bottom: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .tableWrap {
    .tableHead {
      margin-bottom: var(--spacing-12);
    }
    .tableTitle {
      font-family: var(--font-2);
      color: var(--clr-primary);
      font-weight: var(--font-bold);
      font-size: var(--font-size-20);
    }
    .tableBody {
      .tableItem {
        padding: var(--spacing-8) 0;
        border-bottom: 1px solid var(--clr-stroke-grey);
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--clr-secondary);
        font-size: var(--font-size-14);
        line-height: 110%;
        .value {
          color: var(--clr-green-2);
          font-size: var(--font-size-16);
          color: var(--clr-primary-black);
          span {
            font-size: var(--font-size-12);
            font-weight: var(--font-regular);
          }
        }
      }
    }
  }
}

.contentWrap {
  padding-bottom: var(--spacing-32);
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 18rem;
    background: var(--clr-primary);
    opacity: 0.5;
    bottom: 0;
  }
  .accordionItem {
    margin-bottom: var(--spacing-32);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .accordionTitle {
    font-size: var(--font-size-20);
    line-height: 110%;
    font-family: var(--font-2);
    font-weight: var(--font-bold);
  }
  .accordionCont {
    p {
      line-height: 150%;
      color: var(--clr-secondary);
      font-size: var(--font-size-14);
    }
  }
}
