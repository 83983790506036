// spacing.scss

:root {
  --spacing-2: 0.2rem;
  --spacing-4: 0.4rem;
  --spacing-6: 0.6rem;
  --spacing-8: 0.8rem;
  --spacing-10: 1rem;
  --spacing-12: 1.2rem;
  --spacing-14: 1.4rem;
  --spacing-16: 1.6rem;
  --spacing-18: 1.8rem;
  --spacing-20: 2rem;
  --spacing-22: 2.2rem;
  --spacing-24: 2.4rem;
  --spacing-26: 2.6rem;
  --spacing-28: 2.8rem;
  --spacing-30: 3rem;
  --spacing-32: 3.2rem;
  --spacing-34: 3.4rem;
  --spacing-36: 3.6rem;
  --spacing-38: 3.8rem;
  --spacing-40: 4rem;
}
