.section {
  .sectionHead {
    margin-bottom: var(--spacing-32);
  }
  .pgTitle {
    font-size: var(--font-size-36);
    line-height: 100%;
    margin-bottom: var(--spacing-20);
    color: var(--clr-primary-black);
    font-family: var(--font-2);
  }
  .subTitle {
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-secondary);
  }
  .ctaWrap {
    margin-top: var(--spacing-32);
    button,
    a {
      display: inline-flex;
      font-size: var(--font-size-12);
      font-weight: var(--font-semibold);
      line-height: 120%;
      padding: var(--spacing-8) 0;
      color: var(--clr-primary);
      text-transform: none;
    }
  }
}
.contentWrap {
  padding: var(--spacing-16);
  border-top: 1px solid var(--clr-stroke-disabled);
  .desc {
    font-size: var(--font-size-14);
    line-height: 150%;
    color: var(--clr-secondary);
  }
}
