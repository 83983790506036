// bs-icon-link-list

.bs-icon-link-list {
  .account-wrap {
    border-bottom: 1px solid var(--clr-stroke-grey);
  }
  .links-section {
    .section-title {
      font-size: var(--font-size-16);
      line-height: 120%;
      color: var(--clr-secondary);
      font-weight: var(--font-semibold);
      margin-bottom: var(--spacing-12);
      padding-left: var(--spacing-16);
      padding-top: var(--spacing-32);
    }
    .mod-icon-link {
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      .icon-wrap {
        flex-shrink: 0;
        padding-left: var(--spacing-16);
        padding-bottom: var(--spacing-20);
      }
      .data-wrap {
        flex-grow: 1;
        padding-right: var(--spacing-16);
        padding-bottom: var(--spacing-20);
        border-bottom: 1px solid var(--clr-stroke-grey);
      }
    }
  }
}
