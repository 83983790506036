@import "../../styles/sass/config/mixin";

.logoContentWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  i {
    display: block;
    margin: 0 auto var(--spacing-32);
    &.success {
      @include svg-icon("../../../../public/assets/icons/tick-circle-checked.svg", 12rem, 12rem);
      background-color: var(--clr-green-2);
    }
    &.error {
      @include svg-icon("../../../../public/assets/icons/close-circle.svg", 12rem, 12rem);
      background-color: #c62e15;
    }
    &.accountError {
      background-image: url("../../../public/assets/icons/account-error.svg");
      background-size: contain;
      width: 12rem;
      height: 12rem;
    }
    &.kycPending {
      background-image: url("../../../public/assets/icons/kyc-pending.svg");
      background-size: contain;
      width: 12rem;
      height: 12rem;
    }
  }
  .title {
    font-size: var(--font-size-32);
    line-height: 110%;
    color: var(--clr-primary-black);
    font-family: var(--font-2);
    font-weight: var(--font-semibold);
    margin-bottom: var(--spacing-20);
    text-align: center;
  }
  .content {
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-secondary);
    text-align: center;
    max-width: 85vw;
    margin: 0 auto;
    @media only screen and (min-width: 768px) {
      max-width: 85%;
    }
  }
  @media only screen and (min-width: 768px) {
    max-width: 50rem;
    margin: 0 auto;
  }
}
.footWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--spacing-8);
  padding: var(--spacing-16);
  button {
    flex-grow: 1;
    min-width: 16rem;
    padding: var(--spacing-14);
    &:nth-of-type(1) {
      max-width: 16rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16rem;
    }
  }
}
