// bs-select

.bs-select {
  .MuiInputLabel-root {
    font-family: var(--font-1);
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-grey-2);
    top: -0.4rem;
    left: 0.8rem;
    z-index: 1;
    transform: translate(0, 20px) scale(1);
    &.Mui-focused,
    &.MuiFormLabel-filled {
      color: var(--clr-secondary);
      top: 0rem;
      position: relative;
      left: 0;
      transform: translate(0, -1.5px) scale(0.75);
      + .MuiInputBase-root {
        border-color: var(--clr-primary-black);
      }
    }
  }
  .MuiInputBase-root {
    border: 1px solid var(--clr-stroke-disabled);
    border-radius: 0.4rem;
    padding: var(--spacing-12) var(--spacing-8) var(--spacing-8);
    background: var(--clr-white);
    display: flex;
    height: 4.8rem;
    align-items: center;
    margin-top: 0;
    button {
      display: none;
    }
    &:before,
    &:after {
      content: none;
    }
    &.Mui-focused {
      border-color: var(--clr-primary-black);
    }
    fieldset {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 1.5rem;
      right: 1.6rem;
      @include svg-icon("../../../../public/assets/icons/arrow-down.svg", 1.6rem, 1.6rem);
      background-color: var(--clr-primary);
    }
    .MuiSvgIcon-root {
      display: none;
    }
  }
  .MuiFormHelperText-root {
    font-family: var(--font-1);
    font-size: var(--font-size-14);
    margin-top: var(--spacing-8);
    margin-left: 0 !important;
    position: relative;
    display: flex;
    column-gap: var(--spacing-6);
    line-height: 1.66;
    color: var(--clr-red);
    &:before {
      content: "";
      @include svg-icon("../../../../public/assets/icons/info-circle-error.svg", 2rem, 2rem);
      background-color: var(--clr-red);
    }
  }
  .MuiSelect-select {
    font-family: var(--font-1);
  }
}
