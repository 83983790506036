.noteWrap {
    margin: 3.6rem 3.5rem auto;
}
.logoWrap {
    width: 14.8rem;
    height: 14.8rem;
    margin: 0 auto var(--spacing-16);
}
.content {
    font-size: var(--font-size-20);
    line-height: 120%;
    max-width: 70vh;
    text-align: center;
    color: var(--clr-secondary);
}
