@import "../../styles/sass/config/mixin";

.logoContentWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    i {
        display: block;
        margin: 0 auto var(--spacing-32);
        &.success {
            @include svg-icon("../../../../public/assets/icons/user-add.svg", 12rem, 12rem);
            background-color: var(--clr-primary);
        }
    }
    .title {
        font-size: var(--font-size-36);
        line-height: 110%;
        color: var(--clr-primary);
        font-family: var(--font-2);
        font-weight: var(--font-semibold);
        margin-bottom: var(--spacing-16);
        text-align: center;
    }
    .description {
        font-size: var(--font-size-14);
        line-height: 120%;
        color: var(--clr-secondary);
        text-align: center;
        max-width: 70vw;
        margin: 0 auto;
    }
}

.footWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-16);
    background-color: var(--clr-main-bg);
    column-gap: var(--spacing-8);
    box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
    @media screen and (min-width: 768px) {
        max-width: 50rem;
        margin: 0 auto;
        box-shadow: none;
    }
    button {
        flex-grow: 1;
        min-width: 16rem;
        &:nth-of-type(1) {
            max-width: 16rem;
        }
        &:last-of-type {
            max-width: initial;
        }
        &:nth-of-type(2) {
            max-width: 16rem;
        }
    }
}
