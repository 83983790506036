// typography.scss

:root {
  // font family
  --font-1: "Josefin Sans", sans-serif;
  --font-2: "Cormorant Garamond", serif;

  //   font sizes
  --font-size-8: 0.8rem;
  --font-size-10: 1rem;
  --font-size-12: 1.2rem;
  --font-size-14: 1.4rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2rem;
  --font-size-22: 2.2rem;
  --font-size-24: 2.4rem;
  --font-size-32: 3.2rem;
  --font-size-36: 3.6rem;
  --font-size-48: 4.8rem;

  //   font weight
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
}
