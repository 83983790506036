// bs-navbar.scss

.bs-navbar {
  height: 5.4rem;
  padding: 0 var(--spacing-6) var(--spacing-6);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--clr-white);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
  z-index: 2;
  .nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-item {
    width: 8rem;
    a {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: var(--spacing-2);
      color: var(--clr-grey-1);
      font-size: var(--font-size-12);
      line-height: 110%;
      position: relative;
      padding: var(--spacing-8) 0 var(--spacing-4);
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 2px;
        background-color: var(--clr-primary);
      }
      i {
        background-color: var(--clr-grey-1);
        &.home {
          background: url("../../../../public/assets/icons/asiaimpact.svg") no-repeat center / contain;
          width: 2.4rem;
          height: 2.4rem;
        }
        &.portfolio {
          @include svg-icon("../../../../public/assets/icons/portfolio-icon.svg", 2.4rem, 2.4rem);
        }
        &.opportunities {
          @include svg-icon("../../../../public/assets/icons/opportunities-icon.svg", 2.4rem, 2.4rem);
        }
        &.chat {
          @include svg-icon("../../../../public/assets/icons/nav-chat.svg", 2.4rem, 2.4rem);
        }
      }
      &.active {
        color: var(--clr-primary);
        font-weight: var(--font-semibold);
        &:before {
          content: "";
        }
        i {
          background-color: var(--clr-primary);
          &.home {
            background: none;
            @include svg-icon("../../../../public/assets/icons/asiaimpact-filled.svg", 2.4rem, 2.4rem);
            background-color: var(--clr-primary);
          }
          &.portfolio {
            @include svg-icon("../../../../public/assets/icons/portfolio-filled-icon.svg", 2.4rem, 2.4rem);
          }
          &.opportunities {
            @include svg-icon("../../../../public/assets/icons/opportunities-filled-icon.svg", 2.4rem, 2.4rem);
          }
          &.chat {
            @include svg-icon("../../../../public/assets/icons/nav-chat-filled.svg", 2.4rem, 2.4rem);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .bs-navbar {
    bottom: auto;
    top: 5.6rem;
    padding-left: 5.2rem;
    padding-right: 5.2rem;
    .nav-list {
      justify-content: flex-start;
      .nav-item {
        width: 152px;
        a {
          &:before {
            top: initial;
            bottom: -4px;
          }
        }
      }
    }
  }
}
