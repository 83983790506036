@import "../../styles/sass/config/mixin";

.cardWrap {
  height: 10rem;
  box-shadow: 2px 2px 5px 0px rgba(38, 50, 24, 0.15);
  background-color: var(--clr-white);
  border-radius: 0.4rem;
  position: relative;
  overflow: hidden;
  a {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-8);
    height: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  .Newsletter {
    position: absolute;
    width: 5.6rem;
    height: 5.6rem;
    top: -0.2rem;
    right: -0.2rem;
    background-image: url(../../../public/assets/newsletter-infography.svg);
    background-repeat: no-repeat;
  }
  .Blog {
    position: absolute;
    width: 5.6rem;
    height: 5.6rem;
    top: -0.2rem;
    right: -0.2rem;
    background-image: url(../../../public/assets/blog-infography.svg);
    background-repeat: no-repeat;
  }
  .cardTitle {
    font-size: var(--font-size-14);
    line-height: 110%;
    font-weight: var(--font-semibold);
    margin-bottom: var(--spacing-8);
    color: var(--clr-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .date {
    font-size: var(--font-size-10);
    line-height: 110%;
    display: block;
    color: var(--clr-grey-1);
  }
  .cardType {
    padding: var(--spacing-4) var(--spacing-8) var(--spacing-2);
    border-radius: 0.2rem;
    border: 0.7px solid var(--clr-primary);
    background-color: var(--clr-white);
    font-size: var(--font-size-10);
    color: var(--clr-primary);
    line-height: 110%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }
  &.typBig {
    height: auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0.8rem;
    &:before {
      content: none;
    }
    a {
      padding: var(--spacing-8) 0 var(--spacing-12);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: radial-gradient(
          ellipse at center,
          #193f34 0%,
          rgba(25, 63, 52, 0) 51%,
          rgba(25, 63, 52, 0) 51%,
          rgba(25, 63, 52, 0) 51%,
          rgba(25, 63, 52, 0) 100%
        );
      }
    }
    .cardTitle {
      font-size: var(--font-size-16);
      margin-bottom: var(--spacing-16);
      color: var(--clr-primary-black);
    }
    .headWrap {
      margin-bottom: var(--spacing-12);
    }
    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        @include svg-icon(
          "../../../public/assets/icons/link-right.svg",
          2rem,
          2rem
        );
        background-color: var(--clr-primary);
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .cardWrap {
    .Newsletter,
    .Blogs {
      background-position: right top;
      right: 0;
      top: 0;
    }
  }
}
