.holdingValueWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: var(--spacing-6);
}
.label {
    padding-top: var(--spacing-4);
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-grey-1);
}
.value {
    font-size: var(--font-size-20);
    line-height: 120%;
    font-weight: var(--font-semibold);
    color: var(--clr-primary-black);
}
