@import "../../styles/sass/config/mixin";

.cardCont {
    margin: var(--spacing-12) var(--spacing-16) auto;
    padding: var(--spacing-8) var(--spacing-6);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
}
.item {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-12);
    padding: var(--spacing-12) var(--spacing-6);
    .lock {
        @include svg-icon("../../../public/assets/icons/lock.svg", 2rem, 2rem);
        background-color: var(--clr-primary);
    }
    .addGallery {
        @include svg-icon("../../../public/assets/icons/gallery-add.svg", 2rem, 2rem);
        background-color: var(--clr-primary);
    }
    .addProfile {
        @include svg-icon("../../../public/assets/icons/profile-add.svg", 2rem, 2rem);
        background-color: var(--clr-primary);
    }
    .text {
        font-size: var(--font-size-14);
        line-height: 120%;
        color: var(--clr-primary);
        padding-top: var(--spacing-2);
    }
}
