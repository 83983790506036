@import "../../styles/sass/config/mixin";

.bidsList {
  .item {
    margin-bottom: var(--spacing-16);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.panelWrap {
  padding-top: var(--spacing-12);
}
.clientDrawer {
  padding: var(--spacing-8) var(--spacing-16) 0;
  .selectionWrap {
    padding: var(--spacing-16) 0 0;
  }
  .clientList {
    .clientItem {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      label {
        display: flex;
        width: 100%;
        padding: var(--spacing-12);
        column-gap: var(--spacing-12);
        border-radius: var(--spacing-4);
        border: 1px solid transparent;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        @include svg-icon("../../../public/assets/icons/radio-border.svg", 2.4rem, 2.4rem);
        background-color: var(--clr-secondary);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: var(--spacing-12);
        transition: all 0.3s ease-in-out;
        flex: none;
        &:checked {
          @include svg-icon("../../../public/assets/icons/radio-filled.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary);
          + label {
            border: 1px solid var(--clr-primary);
          }
        }
        &:disabled {
          @include svg-icon("../../../public/assets/icons/radio-disabled.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-action-disabled);
          + label {
            cursor: not-allowed;
            border: none;
            .client {
              color: var(--clr-grey-2) !important;
            }
          }
        }
      }
      .client {
        color: var(--clr-secondary);
        font-size: var(--font-size-14);
        line-height: 120%;
        font-weight: var(--font-semibold);
        flex-grow: 1;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .bidsList {
    display: flex;
    row-gap: var(--spacing-16);
    flex-wrap: wrap;
    .item {
      width: 50%;
      &:nth-of-type(odd) {
        padding-right: var(--spacing-16);
      }
      &:nth-of-type(even) {
        padding-left: var(--spacing-16);
      }
    }
  }
}
