.section {
  padding: 0 var(--spacing-16);
  position: absolute;
  top: 25vh;
  .sectionHead {
    margin-bottom: 2.4rem;
  }
  .profileWrap {
    margin-bottom: var(--spacing-4);
    img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .profileTitle {
    color: #183d3d;
    font-size: var(--font-size-16);
    line-height: 120%;
    font-weight: var(--font-semibold);
    text-transform: capitalize;
  }
  .formTitle {
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-secondary);
    margin-bottom: var(--spacing-6);
  }
}
