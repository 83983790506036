// bs-header.scss

.bs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  background-color: var(--clr-main-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: var(--spacing-10) var(--spacing-16);
  .profile-wrap {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-4);
    max-width: 50%;
    flex-shrink: 0;
    cursor: pointer;
    .user-logo {
      @include svg-icon(
        "../../../../public/assets/icons/profile-circle.svg",
        3.6rem,
        3.6rem
      );
      background-color: var(--clr-primary);
    }
    > img {
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      overflow: hidden;
      flex: none;
    }
    .intro-text {
      font-size: var(--font-size-16);
      font-weight: var(--font-semibold);
      color: var(--clr-primary);
      line-height: 110%;
      padding-top: var(--spacing-6);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .icon-title-wrap {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-12);
    button {
      padding: 0;
    }
    .back-icon {
      display: block;
      @include svg-icon(
        "../../../../public/assets/icons/link-left.svg",
        2.4rem,
        2.4rem
      );
      background-color: var(--clr-primary);
    }
  }
  .pg-title {
    font-size: var(--font-size-20);
    font-weight: var(--font-bold);
    color: var(--clr-primary);
    line-height: 110%;
    font-family: var(--font-2);
  }
  .advisor-chat {
    padding: var(--spacing-8) var(--spacing-12);
    background-color: var(--clr-light-green);
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: var(--spacing-8);
    column-gap: var(--spacing-4);
    color: var(--clr-primary-black);
    font-size: var(--font-size-12);
    line-height: 110%;
    border: 0.6px solid rgba(25, 63, 52, 0.12);
    height: 3.2rem;
    i {
      @include svg-icon(
        "../../../../public/assets/icons/message-text.svg",
        1.6rem,
        1.6rem
      );
      background-color: var(--clr-primary-black);
    }
  }
  .act-wrap {
    display: flex;
    column-gap: var(--spacing-16);
    button {
      padding: 0;
      display: block;
      &.notification {
        position: relative;
        i {
          display: block;
          @include svg-icon(
            "../../../../public/assets/icons/notification.svg",
            2.4rem,
            2.4rem
          );
          background-color: var(--clr-primary);
        }
        .notification-count {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--clr-white);
          font-size: var(--font-size-12);
          font-weight: var(--font-medium);
          background-color: var(--clr-red);
          position: absolute;
          top: -0.4rem;
          right: -0.4rem;
          padding: 0 var(--spacing-4);
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      &.share {
        i {
          @include svg-icon(
            "../../../../public/assets/icons/export.svg",
            2.4rem,
            2.4rem
          );
          background-color: var(--clr-primary-black);
          display: block;
        }
      }
      .favorite {
        @include svg-icon(
          "../../../../public/assets/icons/star.svg",
          2.4rem,
          2.4rem
        );
        background-color: var(--clr-grey-2);
        display: block;
      }
      .favorite-filled {
        @include svg-icon(
          "../../../../public/assets/icons/star-filled.svg",
          2.4rem,
          2.4rem
        );
        background-color: var(--clr-grey-3);
        display: block;
      }
      &.filter-btn {
        display: flex;
        align-items: center;
        column-gap: var(--spacing-4);
        padding: var(--spacing-6) var(--spacing-12) var(--spacing-4);
        height: 3rem;
        font-size: var(--font-size-12);
        line-height: 120%;
        color: var(--clr-primary-black);
        background-color: var(--clr-light-green);
        border-radius: 0.4rem;
        i {
          display: block;
          @include svg-icon(
            "../../../../public/assets/icons/filter-icon.svg",
            1.6rem,
            1.6rem
          );
          background-color: var(--clr-primary-black);
        }
      }
      &.delete {
        i {
          @include svg-icon(
            "../../../../public/assets/icons/trash.svg",
            1.6rem,
            1.6rem
          );
          background-color: var(--clr-primary-black);
          display: block;
        }
      }
      &.download {
        i {
          @include svg-icon(
            "../../../../public/assets/icons/document-download.svg",
            2.4rem,
            2.4rem
          );
          background-color: var(--clr-primary-black);
          display: block;
        }
      }
      &.more {
        i {
          @include svg-icon(
            "../../../../public/assets/icons/more-filled.svg",
            2.4rem,
            2.4rem
          );
          background-color: var(--clr-primary-black);
          display: block;
        }
      }
    }
  }
  .progress-wrap {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0;
    border-radius: 50%;
    background: radial-gradient(closest-side, #f3f6ee 80%, transparent 81% 100%),
      conic-gradient(#193f34 var(--progress-count), #e5e7e6 0);
    .step {
      font-size: var(--font-size-14);
      color: var(--clr-primary-black);
      padding-top: var(--spacing-2);
    }
  }
  &.center {
    .icon-title-wrap {
      flex: 1;
    }
    .pg-title {
      flex: 1;
      text-align: center;
      max-width: 60%;
      margin: 0 auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media only screen and (min-width: 768px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
