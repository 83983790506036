@import "../../styles/sass/config/mixin";

.cardWrap {
  padding: var(--spacing-12);
  background-color: var(--clr-white);
  border: 1px solid var(--clr-stroke-grey);
  border-radius: var(--spacing-4);
  .headWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-16);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      border-bottom: 1px solid var(--clr-stroke-grey);
      bottom: -1.2rem;
      width: 100%;
    }
  }
  .footWrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media only screen and (min-width: 1280px) {
      column-gap: 1rem;
    }
  }
  .cardTitle,
  .cardDesc {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
  }
  .cardDesc {
    padding: var(--spacing-10) 0 0;
    color: var(--clr-primary-black);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .date {
    background-color: var(--clr-surface-blue);
    padding: var(--spacing-4) var(--spacing-8) var(--spacing-2)
      var(--spacing-24);
    height: 2rem;
    border-radius: 0.2rem;
    border: 0.2px solid var(--clr-stroke-grey);
    font-size: var(--font-size-10);
    line-height: 120%;
    color: var(--clr-blue);
    display: flex;
    align-items: flex-end;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      @include svg-icon(
        "../../../../public/assets/icons/calendar.svg",
        1.2rem,
        1.2rem
      );
      background-color: var(--clr-blue);
      left: var(--spacing-8);
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .cardTag {
    font-size: var(--font-size-10);
    line-height: 120%;
    color: var(--clr-red-light);
    border: 0.5px solid var(--clr-red-light);
    border-radius: var(--spacing-2);
    padding: 0.5rem var(--spacing-8) var(--spacing-2) var(--spacing-16);
    text-transform: uppercase;
    height: max-content;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: var(--clr-red-light);
        left: var(--spacing-8);
        top: 50%;
        transform: translateY(-50%);
    }
  }
}
