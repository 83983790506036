@import "../../styles/sass/config/mixin";

.typCenter {
  display: flex !important;
}
.bsWrapper {
  display: flex;
  flex-direction: column;
  &.small {
    height: 42.1rem;
  }
  &.medium {
    height: 85vh;
    max-height: 55.5rem;
  }
  &.large {
    height: 85vh;
    max-height: 69.1rem;
  }
  .headWrap {
    padding: var(--spacing-16);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--clr-white);
    flex-shrink: 0;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    button {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--clr-surface-grey);
      border-radius: 50%;
      padding: 0;
      flex-shrink: 0;
      i {
        @include svg-icon("../../../public/assets/icons/close-fill.svg", 2.4rem, 2.4rem);
        background-color: var(--clr-primary);
      }
    }
  }
  .titleWrap {
    &.center {
      width: 100%;
      text-align: center;
      margin-right: -3.2rem;
    }
  }
  .title {
    font-size: var(--font-size-20);
    line-height: 120%;
    font-weight: var(--font-semibold);
    color: var(--clr-primary-black);
    padding-top: var(--spacing-4);
    word-break: break-word;
  }
  .subtext {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
    margin-top: var(--spacing-4);
    max-width: 90%;
  }
  .contWrap {
    flex-grow: 1;
    overflow-y: auto;
    background-color: var(--clr-white);
    // border-top: 0.5px solid var(--clr-stroke-grey);
  }
  .footWrap {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-16);
    background-color: var(--clr-white);
    column-gap: var(--spacing-8);
    box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
    button {
      flex-grow: 1;
      min-width: 16rem;
      &:nth-of-type(1) {
        max-width: 16rem;
      }
      &:last-of-type {
        max-width: initial;
      }
      &:nth-of-type(2) {
        max-width: 16rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .bsWrapper {
    // max-width: 75rem;
    max-width: 37rem;
    width: 100%;
    margin: 0 auto;
    &.small {
      height: 28rem;
    }
    &.large {
      max-height: 50rem;
    }
  }
}
