@import "../../styles/sass/config/mixin";

.phoneWrap {
  display: flex;
  column-gap: var(--spacing-8);
  align-items: end;
  .countryWrap {
    flex-shrink: 0;
    width: 9.6rem;
    label {
      display: block;
      font-size: var(--font-size-12);
      line-height: 120%;
      margin-bottom: var(--spacing-8);
      color: var(--clr-secondary);
    }
    .fieldWrap {
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--spacing-10) var(--spacing-8);
      height: 4.8rem;
      border-radius: 0.4rem;
      background-color: var(--clr-white);
      border: 1px solid var(--clr-primary);
      justify-content: space-between;
      .countryFlag {
        width: 2.4rem;
        flex-shrink: 0;
      }
      input {
        border: none;
        height: initial;
        min-height: initial;
        padding: 0;
        margin: 0;
        color: var(--clr-primary-black);
        font-size: var(--font-size-16);
        line-height: 100%;
        letter-spacing: 0.32px;
        text-align: right;
        width: inherit;
        margin: 0 var(--spacing-4);
        padding-top: var(--spacing-4);
        font-weight: var(--font-semibold);
      }
      .arrowIcon {
        flex-shrink: 0;
        @include svg-icon("../../../../public/assets/icons/arrow-down.svg", 1.6rem, 1.6rem);
        background-color: #183d3d;
      }
    }
  }
}

.countryDrawer {
  padding: var(--spacing-8) var(--spacing-10) var(--spacing-4);
  .selectionWrap {
    padding: var(--spacing-4) 0;
  }
  .countryList {
    .countryItem {
      border-bottom: 1px solid var(--clr-stroke-grey);
      &:last-of-type {
        border-bottom: none;
      }
    }
    .countryItem {
      label {
        display: flex;
        width: 100%;
        padding: var(--spacing-12);
        column-gap: var(--spacing-12);
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease-in-out;
      }
      input[type="radio"] {
        opacity: 0;
        position: absolute;
        &:checked {
          + label {
            background-color: var(--clr-surface-card);
          }
        }
      }
      .flag {
        width: 2.4rem;
        flex-shrink: 0;
      }
      .country {
        color: var(--clr-secondary);
        font-size: var(--font-size-16);
        line-height: 120%;
        font-weight: var(--font-semibold);
        flex-grow: 1;
      }
      .countryCode {
        color: var(--clr-secondary);
        font-size: var(--font-size-16);
        line-height: 120%;
        font-weight: var(--font-semibold);
        text-align: right;
      }
    }
  }
}
