@import "../../styles/sass/config/mixin";

.title {
  margin-bottom: var(--spacing-16);
}
.subTitle {
  font-size: var(--font-size-14);
  line-height: 120%;
  color: var(--clr-secondary);
}

.subItem {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-12) 0;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      border-bottom: 0.5px solid var(--clr-stroke-grey);
      right: -1.6rem;
      bottom: 0;
      width: calc(100% - 2.8rem);
    }
  }
  .arrowIcon {
    flex-shrink: 0;
    background-color: var(--clr-primary-black);
    @include svg-icon("../../../../public/assets/icons/arrow-right.svg", 1.6rem, 1.6rem);
    margin-top: auto;
    margin-bottom: auto;
  }
}
.logoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-surface-card-2);
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  padding: 0;
  position: relative;
  margin-right: var(--spacing-8);
  img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
  }
  span {
    font-size: var(--font-size-16);
    font-weight: var(--font-semibold);
    color: var(--clr-primary);
    line-height: 120%;
    display: block;
    padding-top: var(--spacing-4);
  }
}
.titleWrap {
  flex: 1;
  .clientName,
  .clientMail {
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-primary-black);
    font-weight: var(--font-normal);
  }
  .clientMail {
    font-size: var(--font-size-14);
  }
}
.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: var(--spacing-16);
  background-color: var(--clr-main-bg);
  column-gap: var(--spacing-8);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  @media screen and (min-width: 768px) {
    max-width: 1170px;
    margin: 0 auto;
    box-shadow: none;
  }
  button {
    flex-grow: 1;
    // min-width: 16.76rem;
    &:nth-of-type(1) {
      max-width: 16.76rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16.76rem;
    }
  }
}
