.title {
  margin-bottom: var(--spacing-16);
}

.subtitle {
  margin-bottom: var(--spacing-32);
  font-size: var(--font-size-14);
  line-height: 120%;
  color: var(--clr-secondary);
}
