@import "../../styles/sass/config/mixin";

.title {
  font-size: var(--font-size-36);
  line-height: 110%;
  font-weight: var(--font-semibold);
  font-family: var(--font-2);
  color: var(--clr-primary-black);
  max-width: 80%;
  margin-bottom: var(--spacing-16);
}
.subTitle {
  font-size: var(--font-size-16);
  line-height: 120%;
  color: var(--clr-secondary);
}

.section {
  margin: var(--spacing-24) 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  .secTitle {
    font-size: var(--spacing-14);
    line-height: 120%;
    color: var(--clr-secondary);
    padding: var(--spacing-8) 0;
    margin-bottom: var(--spacing-4);
  }
  .card {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
    .stepsList {
      padding: var(--spacing-8) var(--spacing-6);
    }
    .steps {
      counter-increment: stepList 1;
      height: 4.8rem;
      padding: 0 var(--spacing-6);
      display: flex;
      align-items: center;
      column-gap: var(--spacing-12);
      color: var(--clr-primary);
      font-size: var(--font-size-16);
      line-height: 120%;
      position: relative;
      &:before {
        content: counter(stepList);
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border: 1.5px solid #828282;
        color: var(--clr-secondary);
        font-size: var(--font-size-12);
        line-height: 1.8rem;
        border-radius: 50%;
        flex: none;
      }
      &:after {
        content: "";
        background-color: var(--clr-stroke-grey);
        width: 2px;
        position: absolute;
        left: 1.65rem;
        bottom: -1.1rem;
        height: 2.2rem;
      }
      &:last-of-type {
        &:after {
          content: none;
        }
      }
      p {
        padding-top: var(--spacing-2);
      }
    }
    .infoCont {
      border: 1px solid var(--clr-stroke-grey);
      padding: var(--spacing-8);
      border-radius: var(--spacing-4);
      background-color: var(--clr-white);
      margin: var(--spacing-8) var(--spacing-8) var(--spacing-4);
      display: flex;
      align-items: center;
      gap: var(--spacing-8);
      .desc {
        font-size: var(--font-size-12);
        line-height: 120%;
        color: var(--clr-primary-black);
        padding: var(--spacing-6) 0 var(--spacing-2);
      }
      .error {
        display: flex;
        flex: none;
        @include svg-icon(
          "../../../../public/assets/icons/info-circle.svg",
          1.6rem,
          1.6rem
        );
        background-color: var(--clr-primary-black);
      }
    }
  }
}
.contWrap {
  margin-bottom: var(--spacing-16);
  &:last-of-type {
    margin-bottom: 0;
  }
  .card {
    padding: var(--spacing-16);
  }
  .cardHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-16);
  }
  .companyWrap {
    display: flex;
    align-items: baseline;
    column-gap: var(--spacing-8);
    max-width: 60%;
  }
  .companyLogo {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
  .companyIntro {
    display: flex;
    gap: var(--spacing-8);
    align-items: flex-start;
    flex-direction: row;
    .companyName {
      font-size: var(--font-size-24);
      line-height: 110%;
      display: block;
      color: var(--clr-primary);
      font-weight: var(--font-semibold);
      font-family: var(--font-2);
      max-width: 75rem;
      width: min-content;
    }
    .tag {
      padding: var(--spacing-6) var(--spacing-10) var(--spacing-4);
      border-radius: 0.2rem;
      border: 0.2px solid var(--clr-stroke-grey);
      font-size: var(--font-size-10);
      line-height: 110%;
      font-weight: var(--font-medium);
      color: var(--clr-primary);
      background: linear-gradient(
        90deg,
        #e5f2e4 0%,
        #e5f2e4 0.01%,
        #eaf3e6 32.72%,
        #f9f7ed 100%
      );
      flex-shrink: 0;
      height: 2rem;
    }
  }
  .cardTag {
    font-size: var(--font-size-10);
    line-height: 120%;
    color: var(--clr-red-light);
    border: 0.5px solid var(--clr-red-light);
    border-radius: var(--spacing-2);
    padding: 0.5rem var(--spacing-8) var(--spacing-2) var(--spacing-16);
    text-transform: uppercase;
    height: max-content;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: var(--clr-red-light);
      left: var(--spacing-8);
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .cardFoot {
    border: 1px solid var(--clr-stroke-grey);
    border-radius: var(--spacing-4);
    padding: var(--spacing-12) var(--spacing-16);
    background-color: var(--clr-white);
  }
  .desc {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
  }
}
.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: var(--spacing-16);
  background-color: var(--clr-main-bg);
  column-gap: var(--spacing-8);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  button {
    flex-grow: 1;
    // min-width: 16.76rem;
    &:nth-of-type(1) {
      max-width: 16.76rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16.76rem;
    }
  }
}
