// bs-profile-card

.bs-profile-card {
    padding: var(--spacing-16) var(--spacing-16) 0;
    &.typ-lg {
        padding: 0;
    }
    .client-profile {
        padding: var(--spacing-12);
        border: 1px solid var(--clr-stroke-grey);
        border-radius: 0.4rem;
        background-color: var(--clr-surface-card);
        display: flex;
        align-items: center;
    }
    .logo-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--clr-surface-card-2);
        width: 4.6rem;
        height: 4.6rem;
        border-radius: 50%;
        padding: 0;
        position: relative;
        margin-right: var(--spacing-8);
        img {
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
        }
        span {
            font-size: var(--font-size-16);
            font-weight: var(--font-semibold);
            color: var(--clr-primary);
            line-height: 120%;
            display: block;
            padding-top: var(--spacing-4);
        }
    }
    .title-wrap {
        padding-top: var(--spacing-6);
    }
    .title-text {
        font-size: var(--font-size-20);
        font-weight: var(--font-normal);
    }
    .mail-text {
        font-size: var(--font-size-16);
        line-height: 120%;
        color: var(--clr-primary-black);
        font-weight: var(--font-normal);
        margin-top: var(--spacing-8);
    }
    .clientMail {
        font-size: var(--font-size-14);
    }
}
