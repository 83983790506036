.cardWrap {
  background-color: var(--clr-white);
  position: relative;
  box-shadow: 2px 2px 7px 0px rgba(38, 50, 24, 0.15);
  height: 45.045vw;
  border-radius: 0.4rem;
  overflow: hidden;
  background-size: cover !important;
  @media only screen and (min-width: 1280px) {
    height: 100%;
  }
  a {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: end;
    padding: var(--spacing-12);
    align-items: flex-start;
  }
  .cardTitle {
    font-size: var(--font-size-14);
    font-weight: var(--font-semibold);
    color: var(--clr-white);
    margin: var(--spacing-8) 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .tag {
    margin-bottom: auto;
  }
  .date {
    font-size: var(--font-size-10);
    font-weight: var(--font-medium);
    line-height: 110%;
    color: var(--clr-secondary-grey);
  }
  &.typSmall {
    height: 100%;
    a {
      justify-content: space-between;
      padding: var(--spacing-8);
    }
    .cardTitle {
      margin-top: auto;
      line-height: 1.6rem;
      height: 3rem;
    }
  }
}
