.logoContentWrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  @media screen and (min-width: 768px) {
    position: static;
    transform: none;
    max-width: 1170px;
  }
  .image {
    width: 14rem;
    height: 14rem;
    margin: 0 auto var(--spacing-32);
  }
  .contentWrap {
    padding: 0 var(--spacing-18);
  }
  .title {
    font-size: var(--font-size-36);
    font-weight: var(--font-semibold);
    line-height: 120%;
    color: var(--clr-primary);
    font-family: var(--font-2);
    margin-bottom: var(--spacing-16);
    text-align: center;
  }
  .description {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
    max-width: 70vw;
    margin: 0 auto;
    text-align: center;
    margin-bottom: var(--spacing-16);
  }
}

.footWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16);
  column-gap: var(--spacing-8);
  button {
    flex-grow: 1;
    min-width: 16rem;
    &:nth-of-type(1) {
      max-width: 16rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16rem;
    }
  }
}
