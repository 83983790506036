.newsDetailWrap {
  margin: 0 -1.6rem;
  .imgWrap {
    width: 100vw;
    height: 56vw;
    position: relative;
    margin-bottom: var(--spacing-8);
    img {
      height: 100%;
    }
    .tag {
      position: absolute;
      top: var(--spacing-16);
      left: var(--spacing-16);
      z-index: 1;
    }
  }
  .contentWrap {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-12);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 18rem;
      background: var(--clr-primary);
      opacity: 0.5;
      bottom: 0;
    }
    .contHead {
      position: relative;
      margin-bottom: var(--spacing-32);
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 18rem;
        background: var(--clr-primary);
        opacity: 0.5;
        bottom: -1.6rem;
      }
      .date {
        display: block;
        font-size: var(--font-size-14);
        line-height: 110%;
        margin: var(--spacing-16) 0;
        color: var(--clr-secondary);
      }
      .newsTitle {
        font-size: var(--font-size-20);
        font-weight: var(--font-semibold);
        color: var(--clr-primary);
      }
    }
  }
}
.actWrap {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
  .newsDetailWrap {
    margin: 0;
    .imgWrap {
      width: 100%;
      height: 40vh;
    }
    .contentWrap {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
