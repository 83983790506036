@import "../../styles/sass/config/mixin";

.title {
    margin: var(--spacing-24) 0;
}
.subTitle {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
}

.logoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-surface-card-2);
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    padding: 0;
    position: relative;
    margin-right: var(--spacing-8);
    img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
    }
    span {
        font-size: var(--font-size-16);
        font-weight: var(--font-semibold);
        color: var(--clr-primary);
        line-height: 120%;
        display: block;
        padding-top: var(--spacing-4);
    }
}

.secTitle {
    font-size: var(--font-size-20);
    line-height: 120%;
    font-weight: var(--font-semibold);
    color: var(--clr-grey-1);
    margin-bottom: var(--spacing-24);
}
.cardCont {
    margin-bottom: var(--spacing-16);
    &:last-of-type {
        margin-bottom: 0;
    }
}
.cardWrap {
    padding: var(--spacing-12);
    background-color: var(--clr-surface-card);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: var(--spacing-4);
}
.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // column-gap: var(--spacing-6);
    margin-bottom: var(--spacing-24);
    position: relative;
    &::after {
        content: "";
        position: absolute;
        border-bottom: 1px solid var(--clr-stroke-disabled);
        bottom: -1.2rem;
        width: 100%;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}
.textWrap {
    display: flex;
    flex-direction: column;
}
.contTitle,
.text {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-primary-black);
    margin-bottom: var(--spacing-12);
}
.text {
    margin-bottom: var(--spacing-8);
}
.details {
    font-size: var(--spacing-10);
    line-height: 120%;
    color: var(--clr-secondary);
}
.ctaWrap {
    display: flex;
    column-gap: var(--spacing-2);
    border-radius: var(--spacing-6);
    border: 1px solid var(--clr-stroke-grey);
    background-color: var(--clr-white);
    padding: var(--spacing-4);
    button {
        text-transform: uppercase;
        padding: var(--spacing-10) var(--spacing-12) var(--spacing-6) !important;
        font-size: var(--font-size-12) !important;
        min-width: max-content !important;
        border-radius: var(--spacing-4) !important;
    }
}
