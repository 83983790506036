.card {
    padding: var(--spacing-16) var(--spacing-12);
    border: 1px solid var(--clr-primary);
    border-radius: 0.8rem;
    &.disabled {
        border-color: var(--clr-stroke-grey);
    }
}
.titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-32);
    position: relative;
    &::after {
        content: "";
        position: absolute;
        border-bottom: 1px solid var(--clr-stroke-grey);
        width: 100%;
        bottom: -1.6rem;
    }
    button {
        text-transform: uppercase;
        font-size: var(--font-size-12);
        line-height: 120%;
        padding: var(--spacing-8) 0;
    }
}
.cardTitle {
    font-size: var(--font-size-20);
    line-height: 110%;
    font-weight: var(--font-bold);
    font-family: var(--font-2);
    color: var(--clr-primary);
}
