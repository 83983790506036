@import "../../styles/sass/config/mixin";

.introScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.logoContentWrap {
  width: 100%;
  i {
    display: block;
    margin: 0 auto 2.2rem;
    &.danger {
      @include svg-icon(
        "../../../../public/assets/icons/danger.svg",
        14.8rem,
        14.8rem
      );
      background-color: var(--clr-primary);
    }
  }
  .title {
    font-size: var(--font-size-20);
    line-height: 120%;
    color: var(--clr-primary);
    font-weight: var(--font-semibold);
    margin-bottom: var(--spacing-16);
    text-align: center;
  }
  .description {
    font-size: var(--font-size-14);
    line-height: 150%;
    color: var(--clr-secondary);
    text-align: center;
    max-width: 70vw;
    margin: 0 auto;
  }
  .warnNote {
    font-size: var(--font-size-16);
    line-height: 120%;
    font-weight: var(--font-semibold);
    color: var(--clr-primary);
    margin-top: var(--spacing-36);
    text-align: center;
  }
}

.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16);
  background-color: var(--clr-main-bg);
  column-gap: var(--spacing-8);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  button {
    flex-grow: 1;
    min-width: 16rem;
    &:nth-of-type(1) {
      max-width: 16rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16rem;
    }
  }
}

.drawer {
  padding: var(--spacing-16) var(--spacing-16) 0;
  .title {
    font-size: var(--font-size-16);
    line-height: 120%;
    color: var(--clr-secondary);
  }
  .list {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-14);
    margin-top: var(--spacing-26);
  }
  .item {
    display: flex;
    label {
      font-size: var(--font-size-14);
      line-height: 120%;
      color: var(--clr-primary);
      width: 100%;
      margin-left: var(--spacing-8);
    }
    input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      flex: none;
      @include svg-icon(
        "../../../../public/assets/icons/radio-border.svg",
        1.6rem,
        1.6rem
      );
      background-color: var(--clr-secondary);
      &:checked {
        @include svg-icon(
          "../../../../public/assets/icons/radio-filled.svg",
          1.6rem,
          1.6rem
        );
        background-color: var(--clr-primary);
      }
      &:disabled {
        @include svg-icon(
          "../../../../public/assets/icons/radio-disabled.svg",
          1.6rem,
          1.6rem
        );
        background-color: var(--clr-action-disabled);
        + label {
          cursor: not-allowed;
        }
      }
    }
  }
  .content {
    padding: var(--spacing-12);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.8rem;
    background-color: var(--clr-surface-light);
    margin-top: var(--spacing-16);
    display: flex;
    gap: var(--spacing-8);
    span {
      font-size: var(--font-size-12);
      line-height: 150%;
      color: var(--clr-primary);
    }
    .error {
      display: flex;
      flex: none;
      @include svg-icon(
        "../../../../public/assets/icons/info-circle-filled.svg",
        1.6rem,
        1.6rem
      );
      background-color: var(--clr-primary);
    }
  }
}
