.cardTitle {
  font-size: var(--font-size-20);
  color: var(--clr-primary-black);
  font-weight: var(--font-semibold);
}
.footWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: var(--spacing-16);
  background-color: var(--clr-main-bg);
  column-gap: var(--spacing-8);
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  button,
  a {
    flex-grow: 1;
    // min-width: 16.76rem;
    &:nth-of-type(1) {
      max-width: 16.76rem;
    }
    &:last-of-type {
      max-width: initial;
    }
    &:nth-of-type(2) {
      max-width: 16.76rem;
    }
  }
}
.contWrap {
  margin-bottom: var(--spacing-16);
  &:last-of-type {
    margin-bottom: 0;
  }
  .card {
    padding: var(--spacing-12);
    background-color: var(--clr-white);
    border-radius: var(--spacing-4);
    margin-bottom: var(--spacing-16);
  }
  .cardHead {
    margin-bottom: var(--spacing-16);
  }
  .companyWrap {
    display: flex;
    column-gap: var(--spacing-8);
    align-items: center;
  }
  .companyLogo {
    width: 1.4rem;
    height: 1.4rem;
    flex-shrink: 0;
    align-self: center;
  }
  .companyIntro {
    .companyName {
      font-size: var(--font-size-16);
      line-height: 110%;
      display: block;
      color: var(--clr-primary);
      font-weight: var(--font-bold);
      font-family: var(--font-2);
    }
  }
  .tag {
    margin-left: auto;
    padding: var(--spacing-6) var(--spacing-10) var(--spacing-4);
    border-radius: 0.2rem;
    border: 0.2px solid var(--clr-stroke-grey);
    font-size: var(--font-size-10);
    line-height: 110%;
    font-weight: var(--font-medium);
    color: var(--clr-primary);
    background: linear-gradient(
      90deg,
      #e5f2e4 0%,
      #e5f2e4 0.01%,
      #eaf3e6 32.72%,
      #f9f7ed 100%
    );
    flex-shrink: 0;
    height: 2rem;
  }
  .labelValue {
    .label {
      display: block;
      color: var(--clr-secondary);
      font-size: var(--font-size-12);
      margin-bottom: var(--spacing-8);
    }
    .value {
      display: block;
      color: var(--clr-primary-black);
      font-size: var(--font-size-16);
      font-weight: var(--font-semibold);
    }
  }
}
