@import "../../styles/sass/config/mixin";

.onboardingWrap {
  height: 100vh;
  overflow: hidden;
  background-image: url("../../../public/assets/onboarding-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.secCont {
  height: calc(100vh - 10.8rem);
  overflow-y: auto;
}
.logoWrap {
  position: absolute;
  top: 22vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  img {
    height: 5rem;
    width: auto;
  }
  &.typHead {
    top: 2.2rem;
    left: 2.4rem;
    transform: none;
    @media only screen and (min-width: 1280px) {
      max-width: 50rem;
      margin: 0 auto;
      position: static;
    }
  }
}
.languageSelection {
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 12.8rem;
  h2 {
    font-size: var(--font-size-24);
    line-height: 110%;
    font-weight: var(--font-semibold);
    font-family: var(--font-2);
    margin-bottom: var(--spacing-12);
    color: var(--clr-white);
  }
  @media only screen and (min-width: 1280px) {
    max-width: 50rem;
    margin: 0 auto;
  }
}
.footWrap {
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 3.2rem;
  z-index: 2;
  @media only screen and (min-width: 1280px) {
    max-width: 50rem;
    margin: 0 auto;
    bottom: 6rem;
  }
  button,
  a {
    width: 100%;
    height: 4.4rem;
    text-align: center;
    padding: 0;
    border-radius: 0.4rem;
    background-color: #0b2821;
    box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
    color: var(--clr-white);
    font-size: var(--font-size-16);
    line-height: 120%;
    display: flex;
    align-items: center;
    column-gap: var(--spacing-8);
    justify-content: center;
    i {
      &.iconNext {
        @include svg-icon(
          "../../../public/assets/icons/arrow-right.svg",
          1.2rem,
          1.2rem
        );
        background-color: var(--clr-white);
      }
    }
    &.nextSlide {
      padding: 0 2rem;
      width: auto;
      margin-left: auto;
      margin-right: var(--spacing-2);
    }
    &.prevSlide {
      all: unset;
      position: fixed;
      max-width: 10rem;
      left: 0;
      top: 4rem;
      bottom: 0;
      width: 20%;
      height: auto;
      @media only screen and (min-width: 1280px) {
        left: 40%;
        transform: translate(-120%);
      }
    }
  }
}
.featureWrap {
  position: relative;
  width: 100%;
  // left: 2.4rem;
  // right: 2.4rem;
  top: 15vh;
  @media only screen and (min-width: 1280px) {
    max-width: 50rem;
    margin: 0 auto;
  }
  li {
    display: flex;
    column-gap: var(--spacing-12);
    margin-bottom: var(--spacing-32);
    i {
      flex-shrink: 0;
      background-color: var(--clr-white);
      &.tikIcon {
        @include svg-icon(
          "../../../../public/assets/icons/tick-circle.svg",
          2rem,
          2rem
        );
      }
      &.keyIcon {
        @include svg-icon(
          "../../../../public/assets/icons/key.svg",
          2rem,
          2rem
        );
      }
      &.opportunitiesIcon {
        @include svg-icon(
          "../../../public/assets/icons/opportunities-icon.svg",
          2rem,
          2rem
        );
      }
    }
    p {
      font-size: var(--font-size-16);
      line-height: 120%;
      color: var(--clr-white);
    }
  }
  h2 {
    font-size: var(--font-size-36);
    line-height: 110%;
    font-weight: var(--font-semibold);
    font-family: var(--font-2);
    margin-bottom: var(--spacing-40);
    color: var(--clr-white);
  }
}
.stepsWrap {
  position: absolute;
  left: 0.2rem;
  right: 0.2rem;
  top: 25vh;
  h2 {
    font-size: var(--font-size-48);
    line-height: 110%;
    color: var(--clr-white);
    font-weight: var(--font-medium);
    font-family: var(--font-2);
    span {
      font-size: var(--font-size-20);
      line-height: 120%;
      display: block;
      margin-bottom: var(--spacing-8);
      font-weight: var(--font-regular);
      font-family: var(--font-1);
      opacity: 0.8;
    }
  }
}
.skipBtn {
  font-size: var(--font-size-16);
  line-height: 120%;
  opacity: 0.6;
  color: var(--clr-white);
  position: absolute;
  top: 5.8rem;
  right: 1.8rem;
  z-index: 2;
}
