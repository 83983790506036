.detailsWrap {
  padding: var(--spacing-12);
}
.titleWrap {
  font-size: var(--font-size-20);
  line-height: 110%;
  font-weight: var(--font-semibold);
  font-family: var(--font-2);
}

.title {
  margin-bottom: var(--spacing-24);
}
