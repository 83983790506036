// bs-upload

.bs-upload {
  .doc-card {
    padding: var(--spacing-14) var(--spacing-12) var(--spacing-20);
    background-color: var(--clr-white);
    border-radius: 0.8rem;
    border: 1px solid var(--clr-stroke-disabled);
    .upload-section {
      display: flex;
      column-gap: var(--spacing-10);
      justify-content: space-between;
      position: relative;
      // &:before {
      //   content: "OR";
      //   font-size: var(--font-size-12);
      //   font-weight: var(--font-regular);
      //   color: var(--clr-grey-1);
      //   line-height: 150%;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      // }
    }
    .upload-item {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: var(--spacing-12);
      height: 8rem;
      position: relative;
      border: 2px dashed var(--clr-stroke-grey);
      border-radius: 0.4rem;
      // max-width: calc(50% - 2.3rem);
      max-width: 100%;
      &:before {
        content: "";
      }
      &.upload-file {
        input {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
        }
        &:before {
          @include svg-icon("../../../../public/assets/icons/document-upload.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary-black);
        }
      }
      &.capture-pic {
        input {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
        }
        button {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
        }
        &:before {
          @include svg-icon("../../../../public/assets/icons/camera.svg", 2.4rem, 2.4rem);
          background-color: var(--clr-primary-black);
        }
      }
    }
    .upload-note {
      margin-top: var(--spacing-20);
      .note-title {
        display: flex;
        align-items: end;
        column-gap: var(--spacing-4);
        font-size: var(--font-size-12);
        line-height: 100%;
        font-weight: var(--font-semibold);
        color: var(--clr-red);
        margin-bottom: var(--spacing-12);
        i {
          @include svg-icon("../../../../public/assets/icons/info-circle-error.svg", 1.6rem, 1.6rem);
          background-color: var(--clr-red);
        }
      }
      .note-list {
        padding-left: var(--spacing-8);
      }
      .note-item {
        font-size: var(--font-size-12);
        line-height: 150%;
        position: relative;
        color: var(--clr-grey-1);
        padding-left: var(--spacing-8);
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: var(--clr-grey-1);
        }
      }
    }
  }
  .error-text {
    margin-top: 10px;
    color: var(--clr-red);
    display: flex;
    align-items: flex-end;
    column-gap: var(--spacing-6);
    position: relative;
    &:before {
      content: "";
      @include svg-icon("../../../../public/assets/icons/info-circle-error.svg", 2rem, 2rem);
      background-color: var(--clr-red);
    }
  }
}
