@import "../../styles/sass/config/mixin";

.infoCont {
  border: 1px solid var(--clr-stroke-grey);
  padding: var(--spacing-12);
  border-radius: var(--spacing-8);
  background-color: var(--clr-surface-light);
  display: flex;
  gap: var(--spacing-8);
  max-width: 85%;
  margin: 0 auto var(--spacing-28);
  .desc {
    font-size: var(--font-size-12);
    line-height: 120%;
    color: var(--clr-primary-black);
  }
  .error {
    display: flex;
    flex: none;
    @include svg-icon(
      "../../../../public/assets/icons/info-circle.svg",
      1.6rem,
      1.6rem
    );
    background-color: var(--clr-primary-black);
  }
}
.messageGroup {
  padding: var(--spacing-12) var(--spacing-16);
  flex-grow: 1;
  overflow-y: auto;
  &.hidden {
    display: none;
  }
  .list,
  .item {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-16);
  }
  .item {
    row-gap: var(--spacing-4);
    &.send {
      .wrap {
        background-color: var(--clr-white);
        border-bottom-right-radius: 0;
        margin-right: 0;
        margin-left: auto;
      }
    }
    &.receive {
      .wrap {
        border: 0.2px solid var(--clr-chat-stroke);
        background-color: var(--clr-message-bg);
        border-bottom-left-radius: 0;
      }
      .time {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
  .wrap {
    padding: var(--spacing-12);
    border-radius: var(--spacing-8);
    max-width: 75%;
    width: 100%;
  }
  .text {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-primary-black);
  }
  .time {
    font-size: var(--font-size-12);
    line-height: 120%;
    color: var(--clr-grey-1);
    padding: 0 var(--spacing-4);
    margin-right: 0;
    margin-left: auto;
  }
}
.tabWrap {
  .tabContent {
    padding: var(--spacing-24) var(--spacing-16) 0;
    height: calc(100vh - 20rem);
    background-color: var(--clr-main-bg);
  }
  .headWrap {
    margin-bottom: var(--spacing-32);
  }
  .title {
    font-size: var(--font-size-24);
    line-height: 110%;
    font-weight: var(--font-semibold);
    font-family: var(--font-2);
    color: var(--clr-primary-black);
    padding-top: var(--spacing-8);
    margin-bottom: var(--spacing-12);
  }
  .subTitle {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
    max-width: 90%;
  }
  .section {
    margin: var(--spacing-24) 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    .secTitle {
      font-size: var(--spacing-14);
      line-height: 120%;
      color: var(--clr-secondary);
      padding: var(--spacing-8) 0;
      margin-bottom: var(--spacing-4);
    }
    .card {
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid var(--clr-stroke-grey);
      border-radius: 0.4rem;
      .stepsList {
        padding: var(--spacing-8) var(--spacing-6);
      }
      .steps {
        counter-increment: stepList 1;
        height: 4.8rem;
        padding: 0 var(--spacing-6);
        display: flex;
        align-items: center;
        column-gap: var(--spacing-12);
        color: var(--clr-primary);
        font-size: var(--font-size-16);
        line-height: 120%;
        position: relative;
        &:before {
          content: counter(stepList);
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border: 1.5px solid #828282;
          color: var(--clr-secondary);
          font-size: var(--font-size-12);
          line-height: 1.8rem;
          border-radius: 50%;
          flex: none;
        }
        &:after {
          content: "";
          background-color: var(--clr-stroke-grey);
          width: 2px;
          position: absolute;
          left: 1.65rem;
          bottom: -1.1rem;
          height: 2.2rem;
        }
        &.active {
          &::before {
            content: "";
            background: url("../../../public/assets/icons/play-circle.svg")
              no-repeat center / contain;
            width: 2.4rem;
            height: 2.4rem;
            border: none;
          }
        }
        &:last-of-type {
          &:after {
            content: none;
          }
        }
        p {
          padding-top: var(--spacing-2);
        }
      }
      .infoCont {
        border: 1px solid var(--clr-stroke-grey);
        padding: var(--spacing-8);
        border-radius: var(--spacing-4);
        background-color: var(--clr-white);
        margin: var(--spacing-8) var(--spacing-8) var(--spacing-4);
        display: flex;
        align-items: center;
        gap: var(--spacing-8);
        .desc {
          font-size: var(--font-size-12);
          line-height: 120%;
          color: var(--clr-primary-black);
          padding: var(--spacing-6) 0 var(--spacing-2);
        }
        .error {
          display: flex;
          flex: none;
          @include svg-icon(
            "../../../../public/assets/icons/info-circle.svg",
            1.6rem,
            1.6rem
          );
          background-color: var(--clr-primary-black);
        }
      }
    }
  }
  .footBtn {
    padding: var(--spacing-16);
    box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
    border-top: 1px solid var(--clr-stroke-disabled);
    background-color: var(--clr-white);
    z-index: 1;
    button {
      width: 100%;
    }
  }
}

.footWrap {
  z-index: 1;
  box-shadow: 0px -4px 16px 0px rgba(24, 61, 61, 0.04);
  .inputGroup {
    display: flex;
    align-items: flex-end;
    column-gap: var(--spacing-4);
    padding: var(--spacing-12) var(--spacing-16);
    background-color: var(--clr-chat-bg);
    &.hidden {
      display: none;
    }
  }
  .send {
    display: flex;
    background: url(../../../public/assets/icons/send.svg) no-repeat center /
      contain;
    width: 4rem;
    height: 4rem;
  }
  .btnActive {
    .send {
      background: url(../../../public/assets/icons/send-filled.svg) no-repeat
        center / contain;
    }
  }
}
// .uploadCont {
//     padding: 0 var(--spacing-16);
//     .head {
//         display: flex;
//         justify-content: space-between;
//         margin-bottom: var(--spacing-16);
//     }
//     .title {
//         font-size: var(--font-size-14);
//         line-height: 120%;
//         color: var(--clr-secondary);
//     }
//     .optional {
//         font-size: var(--font-size-12);
//         line-height: 120%;
//         color: var(--clr-grey-1);
//     }
// }
// .menuCont {
//     position: absolute;
//     top: 4rem;
//     right: 1.9rem;
//     border-radius: var(--spacing-4);
//     border: 0.5px solid var(--clr-stroke-grey);
//     background-color: var(--clr-white);
//     box-shadow: 4px 8px 20px 0px rgba(0, 0, 0, 0.06);
//     z-index: 2;
//     display: none;
//     &.show {
//         display: block;
//     }
//     .item {
//         font-size: var(--font-size-14);
//         line-height: 120%;
//         color: var(--clr-secondary);
//         border-bottom: 0.5px solid var(--clr-stroke-disabled);
//         &:last-of-type {
//             border-bottom: none;
//         }
//         button {
//             padding: var(--spacing-12) var(--spacing-8);
//         }
//     }
// }

// .rejectDrawer {
//     padding-top: var(--spacing-36);
//     max-width: 80%;
//     margin: 0 auto;
//     .rejectWrap {
//         text-align: center;
//     }
//     .remove {
//         display: block;
//         background: url("../../../public/assets/icons/message-remove.svg") no-repeat center / contain;
//         width: 14.8rem;
//         height: 14.8rem;
//         margin: 0 auto;
//     }
//     .content {
//         font-size: var(--font-size-20);
//         line-height: 120%;
//         color: var(--clr-secondary);
//         margin-top: var(--spacing-16);
//     }
// }
