// bs-confirmation

.bs-confirmation {
  .confirm-card {
    padding: var(--spacing-12);
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem;
    margin-bottom: var(--spacing-16);
    &:last-of-type {
      margin-bottom: 0;
    }
    .head-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: var(--spacing-8);
      border-bottom: 1px solid var(--clr-stroke-grey);
      margin-bottom: var(--spacing-8);
      button {
        font-size: var(--font-size-12);
      }
    }
    .card-title {
      font-size: var(--font-size-16);
      line-height: 120%;
      color: var(--clr-secondary);
      font-weight: var(--font-regular);
    }
    .label-value-list {
      .label-value-item {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .label-value-item {
      margin-bottom: var(--spacing-12);
      label {
        font-size: var(--font-size-12);
        line-height: 120%;
        color: var(--clr-secondary);
        display: block;
        margin-bottom: var(--spacing-4);
      }
      .value {
        font-size: var(--font-size-14);
        line-height: 120%;
        color: var(--clr-primary-black);
        display: block;
        text-transform: capitalize;
        word-break: break-word;
        &.small {
          text-transform: none;
        }
      }
      Button {
        position: relative;
        top: -42px;
        font-size: 12px;
        float: right;
      }
    }
  }
  .bs-accordion {
    border: 1px solid var(--clr-stroke-grey);
    border-radius: 0.4rem !important;
    overflow: hidden;
    margin-bottom: var(--spacing-16);
    &:before {
      content: none;
    }
    &.MuiAccordion-root {
      &.Mui-expanded {
        margin: 0;
        margin-bottom: var(--spacing-16);
        .MuiAccordionSummary-root {
          &:before {
            transform: translateY(-50%) rotateZ(180deg);
          }
        }
      }
      .MuiAccordionDetails-root {
        padding: var(--spacing-12);
      }
    }
    .MuiAccordionSummary-root {
      padding: var(--spacing-12);
      background-color: var(--clr-white);
      &:before {
        top: 50%;
        right: 1.2rem;
        width: 1.6rem;
        height: 1.6rem;
        transform: translateY(-50%) rotateZ(0);
      }
    }
  }
  .cont {
    .item {
      margin-bottom: var(--spacing-16);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .bs-form {
    .form-group {
      .MuiInputLabel-root {
        &.MuiFormLabel-filled {
          + .MuiOutlinedInput-root {
            border-color: var(--clr-stroke-grey);
          }
        }
      }
    }
  }
}
