@import "../../styles/sass/config/mixin";

.portfolioHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: var(--spacing-12);
  padding: var(--spacing-16);
  .companyWrap {
    display: flex;
    column-gap: var(--spacing-8);
    max-width: 60%;
  }
  .companyLogo {
    width: 3.6rem;
    height: 3.6rem;
    flex-shrink: 0;
    // align-self: center;
  }
  .companyIntro {
    display: flex;
    gap: var(--spacing-8);
    align-items: flex-start;
    flex-direction: column;
    .companyName {
      font-size: var(--font-size-24);
      line-height: 110%;
      display: block;
      color: var(--clr-primary);
      font-weight: var(--font-semibold);
      font-family: var(--font-2);
    }
    .tag {
      padding: var(--spacing-6) var(--spacing-10) var(--spacing-4);
      border-radius: 0.2rem;
      border: 0.2px solid var(--clr-stroke-grey);
      font-size: var(--font-size-10);
      line-height: 110%;
      font-weight: var(--font-medium);
      color: var(--clr-primary);
      background: linear-gradient(
        90deg,
        #e5f2e4 0%,
        #e5f2e4 0.01%,
        #eaf3e6 32.72%,
        #f9f7ed 100%
      );
      flex-shrink: 0;
      height: 2rem;
    }
  }
  .dataWrap {
    .label {
      display: block;
      text-align: right;
      font-size: var(--font-size-12);
      line-height: 110%;
      color: var(--clr-secondary);
      margin-bottom: var(--spacing-12);
    }
    .value {
      display: block;
      text-align: right;
      font-size: var(--font-size-20);
      line-height: 110%;
      font-weight: var(--font-semibold);
      color: var(--clr-green-1);
    }
  }
}
.imgWrap {
  width: 100vw;
  height: 56vw;
  position: relative;
  img {
    height: 100%;
  }
}
.subItem {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-12);
  padding: var(--spacing-4) 0;
  margin-bottom: var(--spacing-16);
  position: relative;
  &::after {
    content: "";
    position: absolute;
    border-bottom: 0.5px solid var(--clr-stroke-grey);
    bottom: -0.8rem;
    left: 0;
    right: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    &::after {
      content: none;
    }
  }
  .growth {
    @include svg-icon(
      "../../../public/assets/icons/growth.svg",
      2.4rem,
      2.4rem
    );
    background-color: var(--clr-primary);
  }
  .industry {
    @include svg-icon(
      "../../../public/assets/icons/industry.svg",
      2.4rem,
      2.4rem
    );
    background-color: var(--clr-primary);
  }
  .climate {
    @include svg-icon(
      "../../../public/assets/icons/climate.svg",
      2.4rem,
      2.4rem
    );
    background-color: var(--clr-primary);
  }
  .text {
    font-size: var(--font-size-14);
    line-height: 120%;
    color: var(--clr-secondary);
    padding-top: var(--spacing-4);
  }
}
.labelValueList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-16) var(--spacing-24);
  .label {
    display: block;
    font-size: var(--font-size-12);
    margin-bottom: var(--spacing-10);
    color: var(--clr-secondary);
    line-height: 100%;
  }
  .value {
    display: block;
    font-size: var(--font-size-14);
    color: var(--clr-primary-black);
    line-height: 100%;
    font-weight: var(--font-semibold);
  }
  .labelValueItem {
    &:nth-of-type(2) {
      .label,
      .value {
        text-align: center;
      }
    }
    &:last-of-type {
      .label,
      .value {
        text-align: right;
      }
    }
  }
}
.labelValueWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: 0;
  max-width: max-content;
  row-gap: var(--spacing-10);
  padding-top: var(--spacing-6);
}
.label {
  font-size: var(--font-size-14);
  color: var(--clr-secondary);
}
.value {
  font-size: var(--font-size-20);
  font-weight: var(--font-semibold);
  color: var(--clr-primary-black);
}

.panelWrap {
  padding: var(--spacing-14) var(--spacing-16) var(--spacing-40);
  background-color: #f5f5f5;
}
.overviewList {
  .cardItem {
    padding: var(--spacing-12);
    border-radius: 0.4rem;
    background-color: var(--clr-white);
    margin-bottom: var(--spacing-16);
    &:last-of-type {
      margin-bottom: 0;
    }
    .cardHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-16);
    }
    .cardTitle {
      font-size: var(--font-size-20);
      line-height: 110%;
      font-family: var(--font-2);
      color: var(--clr-primary);
      font-weight: var(--font-bold);
    }
    .cardDesc {
      font-size: var(--font-size-14);
      line-height: 150%;
      color: var(--clr-primary-black);
      + .tableWrap {
        margin-top: var(--spacing-16);
      }
      + .subWrap {
        margin-top: var(--spacing-16);
      }
    }
    .tableWrap {
      .tableBody {
        .tableItem {
          padding: var(--spacing-8) 0;
          border-bottom: 0.5px solid var(--clr-stroke-grey);
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--clr-secondary);
          font-size: var(--font-size-14);
          line-height: 110%;
          margin-bottom: var(--spacing-12);
          .value {
            font-size: var(--font-size-16);
            color: var(--clr-primary-black);
          }
          &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.graphTab {
  display: flex;
  align-items: center;
  padding: var(--spacing-2);
  border: 1px solid var(--clr-stroke-grey);
  border-radius: 2.9rem;
  column-gap: var(--spacing-2);
  justify-content: space-between;
  .tabPill {
    width: 7.2rem;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-10) var(--spacing-12) var(--spacing-8);
    font-size: var(--font-size-12);
    color: var(--clr-primary-black);
    border-radius: 3.4rem;
    &.active {
      background-color: var(--clr-primary);
      color: var(--clr-white);
      font-weight: var(--font-semibold);
    }
  }
}
.graphFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacing-16) 0;
  button {
    width: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8rem;
    border-radius: 0.4rem;
    color: var(--clr-secondary);
    font-size: var(--font-size-14);
    padding: var(--spacing-2) 0 0;
    text-transform: uppercase;
    border: 1px solid var(--clr-stroke-grey);
    &.active {
      background-color: var(--clr-primary);
      color: var(--clr-white);
      font-weight: var(--font-semibold);
      border-color: var(--clr-primary);
    }
  }
}
.graphNote {
  display: block;
  text-align: right;
  color: var(--clr-grey-1);
  font-size: var(--font-size-10);
  line-height: 120%;
}
.reportsList {
  display: flex;
  row-gap: var(--spacing-16);
  flex-wrap: wrap;
  .item {
    width: 50%;
    &:nth-of-type(even) {
      padding-left: var(--spacing-6);
    }
    &:nth-of-type(odd) {
      padding-right: var(--spacing-6);
    }
    > div {
      box-shadow: 2px 2px 4px 0px #e5ebda;
    }
  }
}
.requestsWrap {
  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-12);
  }
  .listTitle {
    font-size: var(--font-size-20);
    line-height: 110%;
    color: var(--clr-primary-black);
    font-family: var(--font-2);
    font-weight: var(--font-bold);
  }
  .sortBtn {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-4);
    background-color: var(--clr-light-green);
    height: 3rem;
    padding: 0 var(--spacing-12);
    font-size: var(--font-size-12);
    color: var(--clr-primary-black);
    border-radius: 0.4rem;
    i {
      @include svg-icon(
        "../../../../public/assets/icons/sort.svg",
        1.6rem,
        1.6rem
      );
      background-color: var(--clr-primary-black);
    }
  }
}
.requestsList {
  .requestsItem {
    margin-bottom: var(--spacing-8);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .imgWrap {
    width: 100%;
    height: 40vh;
  }
}
